import React, { useState, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { Grid, Typography, Box, Accordion, AccordionSummary, AccordionDetails, Stack, useMediaQuery } from "@mui/material";
import CustomIcon from "../../images/icons/angle-down.svg";
import { theme } from "../../themeV2";

function ReviewStep({ personalData, selectedProducts, selectedDataPoints, selectedProductUniverses }) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = [
    {
      title: "Help us to know more about you",
      content: (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <Typography variant="small" color={theme.palette.colors.grey.darker}><Trans>Email</Trans></Typography>
            <Typography>{personalData.email}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="small" color={theme.palette.colors.grey.darker}><Trans>Select your business type</Trans></Typography>
            <Typography textTransform="capitalize">{personalData.businessType}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="small" color={theme.palette.colors.grey.darker}><Trans>Country</Trans></Typography>
            <Typography>{personalData.country}</Typography>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="small" color={theme.palette.colors.grey.darker}><Trans>Company</Trans></Typography>
            <Typography>{personalData.company}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      title: "Select products of your interest",
      content: (
        <Typography textTransform="capitalize">{selectedProducts.map((product) => product.label).join(", ")}</Typography>
      ),
    },
    {
      title: "Build your data feed",
      content: (
        <Grid container spacing={2}>
          {Object.keys(selectedDataPoints).map((tab) => (
            <Grid item xs={12} sm={3} key={tab}>
              <Box mt={1}>
                <Typography fontWeight={600}>{tab}</Typography>
                <Box mt={0.7}>
                  {selectedDataPoints[tab].map((item) => (
                    <ul key={item} style={{ margin: 0 }}>
                      <li style={{ margin: "0", padding: 0 }}>
                        <Typography>{item.label}</Typography>
                      </li>
                    </ul>
                  ))}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      ),
    },
    {
      title: "Define your product universe and other relevant input",
      content: (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Typography variant="small" pb={0.5} color={theme.palette.colors.grey.darker}>Select your range of products (if aplicable)</Typography>
            <Typography>{selectedProductUniverses.range}</Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="small" pb={0.5} color={theme.palette.colors.grey.darker}>Data quality:</Typography>
            {selectedProductUniverses.dataQuality.map((quality) => (
              <ul key={quality} style={{ margin: 0 }}>
                <li style={{ margin: "0", padding: 0 }}>
                  <Typography>{quality.label}</Typography>
                </li>
              </ul>
            ))}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="small" pb={0.5} color={theme.palette.colors.grey.darker}>Markets and Exchanges</Typography>
            {selectedProductUniverses.markets.map((market) => (
              <ul key={market} style={{ margin: 0 }}>
                <li style={{ margin: "0", padding: 0 }}>
                  <Typography>{market.label}</Typography>
                </li>
              </ul>
            ))}
          </Grid>
        </Grid>
      ),
    },
  ];

  const [expandedPanels, setExpandedPanels] = useState(() => (isMobile ? [] : steps.map((_, index) => index)));

  useEffect(() => {
    setExpandedPanels(isMobile ? [] : steps.map((_, index) => index));
  }, [isMobile, steps.length]);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpandedPanels((prev) =>
      (isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)),
    );
  };

  return (
    <Box my={4}>
      <Stack spacing={0.5} color={theme.palette.colors.blue.base}>
        <Typography variant="h4">
          Resume
        </Typography>
        <Typography color={theme.palette.colors.blue.base}>
          Request date: {new Date().toLocaleDateString()}
        </Typography>
      </Stack>

      <Box my={4}>
        {steps.map((step, index) => (
          <Accordion
            key={index}
            expanded={expandedPanels.includes(index)}
            onChange={handleChange(index)}
            sx={{
              borderRadius: "10px",
              boxShadow: "0px 1px 3px 0px rgba(16, 21, 46, 0.12)",
              marginBottom: 1,
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<img src={CustomIcon} alt="custom icon" style={{ width: 14 }} />}
              aria-controls={`step${index + 1}-content`}
              id={`step${index + 1}-header`}
              sx={{
                flexDirection: "row-reverse",
                paddingLeft: 1.4,
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(360deg)",
                  color: theme.palette.colors.red.dark,
                },
                "& .MuiAccordionSummary-expandIconWrapper": {
                  color: theme.palette.colors.red.dark,
                  transform: "rotate(180deg)",
                },
              }}
            >
              <Stack spacing={0.3} pl={1.4}>
                <Typography variant="h6" fontWeight={400} color="secondary">Step {index + 1}</Typography>
                <Typography variant="heading">{step.title}</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0, paddingLeft: 4 }}>
              {step.content}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}

export default ReviewStep;
