import React from "react";
import { Trans } from "@lingui/macro";
import { Button, Stack, Typography, IconButton, Dialog, DialogContent } from "@mui/material";
import ModalIcon from "../images/icons/modal-icon.svg";
import CloseIcon from "../images/icons/close.svg";
import { theme } from "../themeV2";

function ThankRequestModal({ onClose }) {
  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        style: {
          padding: "20px",
          position: "relative",
          width: "350px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          top: "8px",
          right: "8px",
          color: theme.palette.grey[500],
        }}
      >
        <img width={16} src={CloseIcon} alt="close" />
      </IconButton>
      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            <img src={ModalIcon} alt="check" width={56} />
            <Typography pt={1} variant="text" fontWeight={600}>
              <Trans>Thank you for your request</Trans>
            </Typography>
            <Typography color={theme.palette.colors.blue.light}>
              Someone will contact you within the next 48 hours with a proposal.
            </Typography>
            <Typography color={theme.palette.colors.blue.light}>
              Thanks for your interest!
            </Typography>
          </Stack>
          <Button
            variant="secondary"
            onClick={() => onClose()}
            sx={{
            height: "48px",
            boxShadow: "none",
            backgroundColor: theme.palette.colors.red.main,
            color: "white",
            textTransform: "capitalize",
            width: "180px",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: theme.palette.colors.red.dark,
            },
          }}>
            Understood
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ThankRequestModal;
