import React from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { theme } from "../../themeV2";

function StepProgress({ activeStep, onStepClick }) {
  const steps = [
    {
      step: "Step 1",
      label: "Help us to know more about you",
      description: "Please fill out your personal details",
      active: activeStep === 1,
    },
    {
      step: "Step 2",
      label: "Select products of your interest",
      description: "Select the different products that you need in one entry point.",
      active: activeStep === 2,
    },
    {
      step: "Step 3",
      label: "Build your data feed",
      description: "Explore Allfunds Library Space and define the data points of your interest.",
      active: activeStep === 3,
    },
    {
      step: "Step 4",
      label: "Define your product universe and other relevant inputs",
      description: "Help us to understand the scope of your need and send your request.",
      active: activeStep === 4,
    },
  ];

  return (
    <Grid container spacing={2} mt={4}>
      {steps.map((step, index) => {
        const isCompleted = index < activeStep - 1;
        const isActive = step.active;

        return (
          <Grid
            item
            xs={12}
            sm={3}
            key={index}
            justifyContent="space-between"
            display="flex"
            flexDirection="column"
            onClick={() => onStepClick(index + 1)}
            style={{ cursor: "pointer" }}
          >
            <Stack spacing={1}>
              <Typography
                variant="h6"
                color={isActive ? "secondary" : isCompleted ? theme.palette.colors.grey.lighter : theme.palette.colors.blue.lighter}
              >
                {step.step}
              </Typography>
              <Typography variant="h5">
                {step.label}
              </Typography>
              <Typography fontSize={12} color={theme.palette.colors.blue.lightMedium}>
                {step.description}
              </Typography>
            </Stack>
            <Box
              border={3}
              borderColor={isActive ? theme.palette.secondary.main : isCompleted ? theme.palette.grey[400] : "grey.300"}
              borderRadius={5}
              my={1}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default StepProgress;
