import React, { useState } from "react";
import { Grid, Button, Typography, Tabs, Tab, Stack, Checkbox, FormControlLabel, Box } from "@mui/material";
import { theme } from "../../themeV2";

function FormStep3({ selectedDataPoints, handleDataPointChange }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const dataPointOptions = {
    "Mutual funds": [
      { value: "documentation", label: "Documentation", description: "Complete range of marketing and regulatory documentation associated to the fund" },
      { value: "prices_&_dividends", label: "Prices & dividends", description: "Daily NAVs and dividends as well as adjusted NAVs with stock splits" },
      { value: "operational_&_fund_characteristics", label: "Operational & Fund characteristics", description: "Essential information associated to each fund" },
      { value: "calculated_data", label: "Calculated data", description: "Performance and statistics calculations for different periods, updated with the last NAV available" },
      { value: "regulatory", label: "Regulatory", description: "European templates following the latest regulation" },
      { value: "aggregated_portfolio_information", label: "Aggregated portfolio information", description: "Top 10 holdings & aggregated information where the fund is invested" },
      { value: "corporate_action", label: "Corporate action", description: "Corporate events that affect the funds, sourced directly from Asset Managers" },
      { value: "esg", label: "ESG", description: "ESG Ratings, SDG Ratings, Impact results, Controversies, PAI & Taxonomy analysis" },
    ],
    "Equity": [
      { value: "prices", label: "Prices", description: "Complete range of marketing and regulatory documentation associated to the fund" },
      { value: "volume", label: "Volume", description: "Daily Navs and dividends as well as adjusted NAVs with " },
      { value: "technical_analysis", label: "Technical Analysis", description: "Essential information associated to each fund" },
      { value: "performance", label: "Performance", description: "Performance and statistics calculations for different periods, updated with the last NAV available" },
      { value: "Heatmap_sentiment", label: "Heatmap & sentiment", description: "European templates following the latest regulation" },
      { value: "esg", label: "ESG", description: "ESG Ratings, SDG Ratings, Impact results, Controversies, PAI & Taxonomy analysis" },
    ],
    "Bonds": [
      { value: "standar_data", label: "Standar data", description: "Name, current price, interest(p.a), yield to maturity, maturity date, Issuer" },
      { value: "performance", label: "Performance", description: "Day change, year high, 4 week performance, 12 week performance, YTD" },
      { value: "trader_information", label: "Trader Information", description: "Name, current price, interest(p.a), yield to maturity, maturity date, Issuer" },
      { value: "esg", label: "ESG", description: "Government, Supranational, Corporate and Thematic bonds: ESG Ratings, SDG Ratings, Impact results, Controversies, PAI & Taxonomy analysis" },
  ],
    "Indexes": [
      { value: "standar_data", label: "Standar data", description: "Name, Current price, Day high, Day low" },
      { value: "performance", label: "Performance", description: "Day change(%), 1 week perf.%, 52 week perf.%, 3 Year perf.%, 5 year perf." },
    ],
    "Other products": [
      { value: "currencies", label: "Currencies", description: "Name, Unit, Last value, Day and YTD change (%), 52 weeks comparison…" },
      { value: "precious_metals", label: "Precious metals", description: "Name, CCY, Current value, 52 weeks comparison…" },
      { value: "interests", label: "Precious metals", description: "Name, Current value, Day change, Year high, Year Low, YTD" },
      { value: "crypto", label: "Crypto", description: "Name, Current value, Volume,  Day change, YTD, 52 weeks comparion" },
      { value: "Financial News", label: "financial_news", description: "Market and Regulatory News, Socio-political and Market moving" },
    ],
  };

  const handleDataPointToggle = (option) => {
    const tab = Object.keys(dataPointOptions)[selectedTab];
    const newSelectedDataPoints = { ...selectedDataPoints };

    if (newSelectedDataPoints[tab]?.some((item) => item.value === option.value)) {
      newSelectedDataPoints[tab] = newSelectedDataPoints[tab].filter((item) => item.value !== option.value);
    } else {
      newSelectedDataPoints[tab] = [...(newSelectedDataPoints[tab] || []), option];
    }

    handleDataPointChange(newSelectedDataPoints);
  };

  const isSelected = (option) => {
    const tab = Object.keys(dataPointOptions)[selectedTab];
    return selectedDataPoints[tab]?.some((item) => item.value === option.value);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ borderBottom: "1px solid", borderColor: theme.palette.colors.grey.lighter, marginBottom: "1px", position: "absolute", width: "100%", bottom: "0" }} />
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.colors.red.dark,
            height: "4px",
            borderRadius: "10px",
          },
        }}
      >
          {Object.keys(dataPointOptions).map((tab, index) => (
            <Tab
              key={index}
              label={tab}
              sx={{
              fontSize: "16px",
              color: theme.palette.colors.grey.main,
              "&.Mui-selected": {
                color: theme.palette.colors.blue.dark,
                fontWeight: "bold",
              },
            }}
          />
        ))}
        </Tabs>
      </Box>
      <Grid container spacing={2} my={4}>
        {dataPointOptions[Object.keys(dataPointOptions)[selectedTab]].map((option, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Button
              fullWidth
              onClick={() => handleDataPointToggle(option)}
              sx={{
                minHeight: "168px !important",
                boxShadow: "0px 1px 3px 0px rgba(16, 21, 46, 0.12)",
                alignItems: "flex-start",
                padding: "0 !important",
                ...(isSelected(option) && {
                  border: `1px solid ${theme.palette.colors.red.light}`,
                  borderRadius: "10px",
                }),
              }}
            >
              <Box p={2} display="flex" alignItems="flex-start" justifyContent="flex-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={(selectedDataPoints[Object.keys(dataPointOptions)[selectedTab]] || []).some((item) => item.value === option.value)}
                      onChange={() => handleDataPointToggle(option)}
                      color="secondary"
                      sx={{ color: theme.palette.colors.grey.main, paddingTop: 0 }}
                  />
                }
                  label=""
                  sx={{ marginRight: 0 }}
              />
                <Stack alignItems="flex-start" textAlign="start" spacing={1}>
                  <Typography variant="body1" fontSize={{ xs: 16, md: 18, lg: 18 }} fontWeight={600}>{option.label}</Typography>
                  <Typography variant="body2">{option.description}</Typography>
                </Stack>
              </Box>
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default FormStep3;
